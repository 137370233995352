import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './components/Login';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Playground from './components/Playground'; // Import Playground component
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute component
import './App.css';

function App() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <Router>
      <div className="App">
        {/* Navigation Bar */}
        <nav className="navbar">
          <div className="navbar-container">
            <Link to="/" className="navbar-logo">
              <img src="/rabbit_logo.png" alt="ChatRabbit Logo" className="navbar-logo-img" />
              ChatRabbit
            </Link>
            <ul className="navbar-menu">
              <li className="navbar-item">
                <Link to="/" className="navbar-link">Home</Link>
              </li>
              <li className="navbar-item">
                <Link to="/features" className="navbar-link">Features</Link>
              </li>
              <li className="navbar-item">
                <Link to="/pricing" className="navbar-link">Pricing</Link>
              </li>
              <li className="navbar-item">
                <Link to="/playground" className="navbar-link">Playground</Link>
              </li>
              <li className="navbar-item">
                {isAuthenticated ? (
                  <button onClick={() => logout({ returnTo: window.location.origin })} className="navbar-link">
                    Logout
                  </button>
                ) : (
                  <button onClick={loginWithRedirect} className="navbar-link">
                    Login
                  </button>
                )}
              </li>
            </ul>
          </div>
        </nav>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<h1>Welcome to ChatRabbit!</h1>} />
          <Route path="/login" element={<Login />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route
            path="/playground"
            element={
              <ProtectedRoute>
                <Playground />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
