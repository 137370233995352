import React from 'react';

function Features() {
  return (
    <div className="features-container">
      <h2>Features</h2>
      <p>This is the Features page. Here, you will list the awesome features of ChatRabbit.</p>
    </div>
  );
}

export default Features;
