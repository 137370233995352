import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Login.css'; // Optional for custom styling

function Login() {
  const { loginWithRedirect, error } = useAuth0();

  return (
    <div className="login-container">
      <h2>Login</h2>
      {error && <p className="error">{error.message}</p>}
      <button onClick={() => loginWithRedirect()}>Log In with Auth0</button>
    </div>
  );
}

export default Login;
