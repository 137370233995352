import React from 'react';

function Pricing() {
  return (
    <div className="pricing-container">
      <h2>Pricing</h2>
      <p>This is the Pricing page. Here, you will provide details about the pricing plans for ChatRabbit.</p>
    </div>
  );
}

export default Pricing;
