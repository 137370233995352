// src/components/Playground.js
import React from 'react';
import ChatBox from './ChatBox';
import './Playground.css';

const Playground = () => {
  return (
    <div className="playground-container">
      <h2>Chat Playground</h2>
      <p>Compare Responses with different AI models.</p>
      <div className="playground-grid">
        <ChatBox title="ChatGPT-4" />
        <ChatBox title="Claude 3.5" />
        <ChatBox title="LLaMA 3" />
        <ChatBox title="Mistral Large" />
      </div>
      <div className="playground-input">
        <input type="text" placeholder="Type your message for all chats..." />
        <button>Send to All</button>
      </div>
    </div>
  );
};

export default Playground;
